<template>
  <div class="customer">
    <el-dialog :visible.sync="dialogFormVisible">
    
    <el-form model="selectedCustomer" label-position="left" label-width="120px">
    <el-form-item label="Customer Name">
     <el-input v-model="selectedCustomer.customerName" autocomplete="off"></el-input>
    </el-form-item>

    <el-form-item label="Alias">
     <el-input v-model="selectedCustomer.alias" autocomplete="off"></el-input>
    </el-form-item>
       
    <el-form-item label="Address">
     <el-input v-model="selectedCustomer.address" autocomplete="off"></el-input>
    </el-form-item>
    
    <el-form-item label="Contact Name">
     <el-input v-model="selectedCustomer.contactName" autocomplete="off"></el-input>
    </el-form-item>

    <el-form-item label="Email">
     <el-input v-model="selectedCustomer.email" autocomplete="off"></el-input>
    </el-form-item>

    <el-form-item label="Telephone">
      <el-input v-model="selectedCustomer.telephone" autocomplete="off"></el-input>
    </el-form-item>

    </el-form>
        
    <div slot="footer">
      <el-button @click="dialogFormVisible = false">Cancel</el-button>
      <el-button type="primary" @click="Confirm">Confirm</el-button>
                   
    </div>
    </el-dialog>

  <el-row>
  <el-form :model="form" label-position="left" label-width="120px">
    <el-form-item label="Customer Name">
      <el-col :span="10"><el-input v-model="form.customerName" placeholder="Please Input Customer Company's Name"></el-input></el-col>
    </el-form-item>

    <el-form-item label="Alias">
      <el-col :span="10"><el-input v-model="form.alias" placeholder="Please Input Alias (ex,GEBIS)"></el-input></el-col>
    </el-form-item>

     <el-form-item label="Address">
      <el-col :span="15"><el-input v-model="form.address" placeholder="Please Input Customer Company's Address"></el-input></el-col>
    </el-form-item>

    <el-form-item label="Contact Name">
      <el-col :span="10"><el-input v-model="form.contactName" placeholder="Please Input Contact Name"></el-input></el-col>
    </el-form-item>

    <el-form-item label="Email">
      <el-col :span="10"><el-input v-model="form.email" placeholder="Please Input Email address"></el-input></el-col>
    </el-form-item>

    <el-form-item label="Telephone">
      <el-col :span="10"><el-input v-model="form.telephone" placeholder="Please Input Telephone"></el-input></el-col>
    </el-form-item>

    <el-form-item>
     <el-col :span="2"><el-button type="primary" size="medium" plain round @click="add">Add</el-button></el-col>
    </el-form-item>

   </el-form>
  </el-row>

  <el-row>
  <el-table
    border
    :data="customers"
    style="width:2000px">
    <el-table-column type="index" fixed></el-table-column>
    <el-table-column width= "350" label="Customer Name" prop="customerName"></el-table-column>
    <el-table-column width= "100" label="Alias" prop="alias"></el-table-column>
    <el-table-column width= "400" label="Address" prop="address"></el-table-column>
    <el-table-column width= "150" label="Contact Name" prop="contactName"></el-table-column>
    <el-table-column width= "150" label="Email" prop="email"></el-table-column>
    <el-table-column width= "150" label="Telephone" prop="telephone"></el-table-column>

    <el-table-column
      fixed="right"
      width="220">
      <template slot-scope="scope">
      <el-button @click="handleClick(scope.row)" type="success" size="medium" plain round>Modify</el-button>
      <el-button @click.native.prevent="deleteRow(scope.$index, customers)"
      type="danger" size="medium" plain round>Delete</el-button>
      </template>
    </el-table-column>

  </el-table>
  </el-row>
  </div>
</template>

<script>
import { axiosInstance } from '@/api'
export default {
  data() {
    return {
      customer: "",
      customers: [],
      dialogFormVisible: false,
      
      selectedCustomer:{
      customerName:'',
      alias:'',
      address:'',
      contactName:'',
      email:'',
      telephone:''
      },

      form: {
      customerName:'',
      alias:'',
      address:'',
      contactName:'',
      email:'',
      telephone:''
      }
      
    }
  },

  created(){
        this.loadData()
    },

    methods: {
      add(){
        axiosInstance.post("/customer",this.form).then((response) => {
         this.loadData()
          })
    },

      loadData(){
         axiosInstance.get("/customer").then((response) => {
          this.customers = response.data;
          })
    },

      handleClick(row) {
        this.selectedCustomer = row;
        this.dialogFormVisible =true;
        console.log(row);
      },
      
      Confirm(){
        axiosInstance.put("/customer/"+this.selectedCustomer.id,
        {customerName: this.selectedCustomer.customerName, alias: this.selectedCustomer.alias, address: this.selectedCustomer.address, contactName: this.selectedCustomer.contactName, 
        email: this.selectedCustomer.email, telephone: this.selectedCustomer.telephone}).then((response) => {
           this.loadData()
        }).finally(()=>{
           this.dialogFormVisible =false;
        })
    },

      deleteRow(index, rows) {
        let row = rows[index];
        this.$confirm('Delete Or Not?', 'CHECK', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          axiosInstance.delete("/customer/"+row.id).then((response) => {
            rows.splice(index, 1);
            this.$message({
            type: "success",
            message: 'Success!!'
          });
          })
         
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancel'
          });          
        });
        
      },
  }
}

</script>

<style lang="scss" scoped>
.customer{
  .el-row {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }

}



</style>